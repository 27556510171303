@import url(https://rsms.me/inter/inter.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  html, body {
    font-family: "Inter var", sans-serif;
  }
}
p {
  font-family: "Inter var", sans-serif;
  font-size: 16px;
  color: #000000;
  margin: 0;
}

.titleText {
  font-family: "Inter var", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

.normalText {
  font-family: "Inter var", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #000000;
  margin: 0;
  opacity: 0.8;
}

.lightText {
  font-family: "Inter var", sans-serif;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  margin: 0;
  opacity: 0.33;
}

a {
  text-decoration: underline;
}

a:hover {
  background: rgba(0, 0, 0, 0.1);
}

.filterText {
  font-weight: 700;
  cursor: pointer;
  transition: 0.2s all;
}

.filterNormal {
  transition: 0.2s all;
  text-decoration: none;
}

.filterNormal:hover {
  background: rgba(0, 0, 0, 0.1);
}

.filterSelected {
  transition: 0.2s all;
  background: rgba(0, 0, 0, 0.1);
}

.filterSelected:hover {
  background: rgba(0, 0, 0, 0.33);
}

.titleBar {
  width: calc(100% - 64px);
  padding: 32px;
  position: fixed;
  top: 0;
  z-index: 2;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

@media only screen and (max-width: 480px) {
  .titleBar {
    width: calc(100% - 32px);
    padding: 16px;
  }
}
.flow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin-top: 120px;
}

.flowItemContainer {
  width: calc((100% - 128px) / 3);
}

.flowItemA {
  width: 100%;
  height: 0;
  padding-bottom: 42.8%;
  margin-bottom: 32px;
  position: relative;
}

.flowItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.flowImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.flowItemOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 64px);
  height: calc(100% - 64px);
  background: white;
  opacity: 0;
  padding: 32px;
  transition: 0.1s all;
}

.flowItemContainer:hover .flowItemOverlay {
  opacity: 0.9;
}

.flowMobile {
  display: none;
}

@media only screen and (max-width: 480px) {
  .flowItemContainer {
    width: calc(100% - 32px);
  }

  .flowItemA {
    margin-bottom: 16px;
  }

  .filterMenu {
    display: none;
  }

  .flowMobile {
    display: block;
    margin-bottom: 32px;
  }
}
.dynamicTitleText {
  font-family: "Inter var", sans-serif;
  font-size: 48px;
  line-height: 64px;
  font-weight: 500;
  color: #000000;
  margin: 0;
  text-align: center;
}

.dynamicHeadingText {
  font-family: "Inter var", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #000000;
  margin: 0;
  margin-bottom: 16px;
  text-align: left;
}

.dynamicCaptionText {
  font-family: "Inter var", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #000000;
  margin: 0;
  text-align: center;
}

.dynamicImageFull {
  width: 100%;
}

.dynamicImageGallery {
  width: 64rem;
  margin-left: -12rem;
  margin-right: -12rem;
}

.dynamicVideo {
  width: 100%;
}

.dynamicPage {
  margin-top: 120px;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 160px;
}

.dynamicWidget {
  position: fixed;
  margin-top: 120px;
  margin-left: 32px;
  left: 0;
  width: 240px;
  top: 0;
}

@media only screen and (max-width: 1280px) {
  .dynamicImageGallery {
    width: 52rem;
    margin-left: -6rem;
    margin-right: -6rem;
  }
}
@media only screen and (max-width: 480px) {
  .dynamicPage {
    margin-top: 120px;
    width: calc(100% - 32px);
  }

  .dynamicImageGallery {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
  }

  .dynamicWidget {
    display: none;
  }
}
.cases {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  max-width: 640px;
}

.caseItemContainer {
  width: 100%;
  margin-bottom: 180px;
}

.caseTitle {
  font-family: "Inter var", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #000000;
  margin: 0;
  text-align: left;
}

@media only screen and (max-width: 480px) {
  .cases {
    width: calc(100% - 32px);
    max-width: 100%;
  }

  .caseItemContainer {
    margin-bottom: 96px;
  }

  .caseTitle {
    padding-top: 8px;
  }
}

/*# sourceMappingURL=App.css.map */

